.innerStyle {
  font-size: 80%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100vw;
}
.innerStyle img {
  max-height: 80vh;
  z-index: 3;
  margin-top: 50px;
  box-shadow: 7px 18px 35px -3px rgba(0, 0, 0, 0.58),
    -14px 8px 14px -9px rgba(0, 0, 0, 0.53),
    -6px -9px 14px -9px rgba(0, 0, 0, 0.53),
    24px -11px 38px 1px rgba(0, 0, 0, 0.51);
}
.innerStyle .closeIcon {
  width: 60vh;
  max-height: 80vh;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -95px;
  z-index: 1000;
  margin-right: 2vh;
}
.closeIcon svg:hover {
  transform: scale(1.3);
}
.innerStyle .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innerStyle svg {
  cursor: pointer;
  transition: 0.6s ease;
}

.btn {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.3em solid #4b596b;
  border-radius: 0.32em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #4b596b;
  text-align: center;
  transition: all 0.2s;
  margin: 60px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
}
.btn span {
  color: #4b596b;
  font-size: 120%;
  font-weight: 700;
  margin-right: 10px;
}
.btn:hover {
  color: #000000;
  background-color: #ffffff;
  transform: scale(1.05);
}
@media only screen and (max-width: 700px) {
  .innerStyle img {
    width: 90vw;
    max-height: 100%;

    box-shadow: 7px 18px 35px -3px rgba(0, 0, 0, 0.28),
      -14px 8px 14px -9px rgba(0, 0, 0, 0.23),
      -6px -9px 14px -9px rgba(0, 0, 0, 0.23),
      24px -11px 38px 1px rgba(0, 0, 0, 0.21);
  }
  .innerStyle .closeIcon {
    width: 90vw;
  }
}
.buttoncontainer {
  display: flex;
  justify-content: center;
  width: 90vw;
}
@media only screen and (max-width: 350px) {
  .buttoncontainer {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 30px;
  }
  .btn {
    margin: 10px 0 0 0;
    width: 90vw;
  }
}
