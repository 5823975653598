.grid {
  display: flex;
  align-items: center;
  width: 100vw;
  align-content: center;
  justify-content: center;
}
.grid > img {
  width: 18vw;
  transition: all 0.2s ease-in;
  cursor: pointer;
  box-shadow: 7px 18px 35px -3px rgba(0, 0, 0, 0.58),
    -14px 8px 14px -9px rgba(0, 0, 0, 0.53),
    -6px -9px 14px -9px rgba(0, 0, 0, 0.53),
    24px -11px 38px 1px rgba(0, 0, 0, 0.51);
}

.grid > img:nth-child(1) {
  z-index: 1;
}
.grid > img:nth-child(1):hover {
  transform: scale(1.1);
  filter: brightness(110%);
  z-index: 50;
}
.grid > img:nth-child(2) {
  margin-top: 110px;
  margin-left: -8vw;
  z-index: 2;
}
.grid > img:nth-child(2):hover {
  transform: scale(1.1);
  filter: brightness(110%);
  z-index: 50;
}

.grid > img:nth-child(3) {
  margin-top: 10px;
  margin-left: -7vw;
  z-index: 3;
}
.grid > img:nth-child(3):hover {
  transform: scale(1.1);
  filter: brightness(110%);
  z-index: 50;
}
.grid > img:nth-child(4) {
  margin-top: 110px;
  margin-left: -6vw;
  z-index: 4;
}
.grid > img:nth-child(4):hover {
  transform: scale(1.1);
  filter: brightness(110%);
}
.grid > img:nth-child(5) {
  margin-top: 10px;
  margin-left: -6vw;
  z-index: 1;
}
.grid > img:nth-child(5):hover {
  transform: scale(1.1);
  filter: brightness(110%);
  z-index: 50;
}
