.header {
  display: grid;
  grid-template-columns: 33.3vw 33.3vw 33.3vw;
  justify-items: center;
  align-items: center;
  height: 150px;
  margin: 30px 0 -20px 0;
}
.header > img {
  align-content: center;
}
.mainText p {
  text-align: center;
  color: rgba(75, 89, 107, 255);
  font-family: sans-serif;
  margin: 0 0 8px 0;
  font-size: 130%;
}
.mainText p:nth-child(1) {
  margin: 0;
}
@media only screen and (max-width: 900px) {
  .mainText p {
    font-size: 100%;
  }
  .header img {
    align-content: center;
    width: 30vw;
  }
}
@media only screen and (max-width: 700px) {
  .header {
    display: grid;
    grid-template-columns: 50vw 50vw;
    justify-items: center;
    align-items: center;

    margin: 30px 0 -20px 0;
  }
  .mainText p {
    font-size: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .header {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .mainText {
    margin-top: 20px;
  }
  .header img {
    align-content: center;
    width: 60vw;
    max-width: 260px;
  }
}
