.imageSlider img {
  width: 100%;
  transition: all 0.3s ease-in;
  filter: brightness(100%);
  cursor: pointer;
}
.imageSlider img:hover {
  transform: scale(1.02);
  filter: brightness(110%);
}
.imageSlider {
  width: 100vw;
  justify-content: center;
  margin-top: 40px;
}
@media only screen and (max-width: 700px) and( min-width:415px) {
  .imageSlider {
    width: 100vw;
    justify-content: center;
    margin-top: 80px;
  }
}
@media only screen and (max-width: 350px) {
  .imageSlider {
    margin-top: 0;
  }
}
.carousel {
  z-index: 111;
}
.leftButton {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 5000px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;

  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightButton {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 5000px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  right: 10px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
