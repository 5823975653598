.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 -20px 0;
  width: 100vw;
}
.footer p {
  font-size: 85%;
  text-align: center;
  font-weight: 700;
}
.footer a {
  color: black;
}

.footer .arrow {
  width: 0.8rem;
  display: inline-block;
  position: relative;
}
.arrow span {
  position: absolute;
  width: 2rem;
  height: 0.39rem;
  background-color: rgba(186, 31, 36, 255);
  display: inline-block;
  transition: all 0.1s ease;
}
.rightLine {
  right: 0;
  transform: rotate(-45deg);
}
.leftLine {
  left: 0;
  transform: rotate(45deg);
}
.leftLineActive {
  left: 0;
  transform: rotate(-45deg);
}
.rightLineActive {
  right: 0;
  transform: rotate(45deg);
}
.footer .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 60px;
  cursor: pointer;
}
.description {
  opacity: 0;
  visibility: hidden;
  margin-top: -250px;
  transition: all 0.3s ease-in;
  width: 30vw;
  z-index: -1;
}
.descriptionActive {
  opacity: 0.9;
  transition: all 0.3s ease;
  width: 65vw;
}
@media only screen and (max-width: 700px) {
  .descriptionActive {
    width: 90vw;
  }
  .description {
    width: 70vw;
  }
  .footer {
    margin: 0 0 -20px 0;
  }
  .footer p {
    font-size: 70%;
  }
}
